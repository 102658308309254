import React from "react";
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
import { Swiper } from 'swiper/react';

// Swiper styles
import 'swiper/scss';
// import 'swiper/scss/a11y' // styles required for A11y module
import 'swiper/scss/autoplay' // styles required for Autoplay module
// import 'swiper/scss/controller' // styles required for Controller module
// import 'swiper/scss/effect-cards' // styles required for Cards Effect module
// import 'swiper/scss/effect-coverflow' // styles required for Coverflow Effect module
// import 'swiper/scss/effect-creative' // styles required for Creative Effect module
// import 'swiper/scss/effect-cube' // styles required for Cube Effect module
import 'swiper/scss/effect-fade' // styles required for Fade Effect module
// import 'swiper/scss/effect-flip' // styles required for Flip Effect module
// import 'swiper/scss/free-mode' // styles required for Free Mode module
// import 'swiper/scss/grid' // styles required for Grid module
// import 'swiper/scss/hash-navigation' // styles required for Hash Navigation module
// import 'swiper/scss/history' // styles required for History module
// import 'swiper/scss/keyboard' // styles required for Keyboard module
// import 'swiper/scss/lazy' // styles required for Lazy module
// import 'swiper/scss/manipulation' // styles required for Manipulation module
// import 'swiper/scss/mousewheel' // styles required for Mousewheel module
import 'swiper/scss/navigation' // styles required for Navigation module
import 'swiper/scss/pagination' // styles required for Pagination module
// import 'swiper/scss/parallax' // styles required for Parallax module
// import 'swiper/scss/scrollbar' // styles required for Scrollbar module
// import 'swiper/scss/thumbs' // styles required for Thumbs module
// import 'swiper/scss/virtual' // styles required for Virtual module
// import 'swiper/scss/zoom' // styles required for Zoom module

const Carousel = ({ children, ...rest }) => {
    return (
        <Swiper
            slidesPerView={1}
            modules={[Navigation, Pagination, Autoplay, EffectFade]}
            pagination={{
                    // el: ".swiper-pagination",
                    clickable: true,
                    // dynamicBullets: true,                
            }}
            {...rest}
        >
            {children}
        </Swiper>
    )
}

export default Carousel